/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import { SVGIcon } from "@doar/components";
import { StyledGaugeStyles, StyledGaugeButton } from "./style";

type TIcon = "gaugeStyle" | "gaugeStyle2" | "bullet" | "bulletInverted";

interface IProps {
    onClick: (type: string) => void;
    selected?: TIcon;
}

const gaugeChartStyles: TIcon[] = ["gaugeStyle", "gaugeStyle2", "bullet", "bulletInverted"];

const GaugeStyles: React.FC<IProps> = ({ onClick, selected = "gaugeStyle" }: IProps) => {
    const [activeStyle, setActiveStyle] = useState<TIcon>(selected);
    const handleClick = (type: TIcon) => {
        onClick(type);
        setActiveStyle(type);
    };

    return (
        <StyledGaugeStyles>
            {gaugeChartStyles.map((style) => (
                <StyledGaugeButton isActive={style === activeStyle} key={style}>
                    <button onClick={() => handleClick(style)}>
                        <SVGIcon
                            type={style}
                            isActive={style === activeStyle}
                            hasFill={true}
                        />
                    </button>
                </StyledGaugeButton>
            ))}
        </StyledGaugeStyles>
    );
};

export default GaugeStyles;
