import styled, { themeGet } from "@doar/shared";

interface IProps {
    isActive: boolean;
    hasFill: boolean;
}

export const StyledSVGIcon = styled.span<IProps>`
    margin: 0;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
        width: 30px;
    }

    &:hover svg {
        fill: ${(props) =>
            props.hasFill === true && themeGet("colors.white")};
        stroke: ${themeGet("colors.white")};
    }

    & .svg-body {
        fill: ${(props) =>
            props.isActive === true
                ? props.hasFill && themeGet("colors.white")
                : props.hasFill && themeGet("colors.databoardsToggleBackground")};
        stroke: ${(props) =>
            props.isActive === true && themeGet("colors.white")};
    }

    & .svg-areaLine {
        stroke: none;
    }

    &:hover .svg-areaLine {
        stroke: none;
    }
`;
