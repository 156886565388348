/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import { StyledSwitchButton } from "./style";

interface IProps {
    onClick: () => void;
    label: string;
    value: boolean;
}

const SwitchButton: React.FC<IProps> = ({ onClick, label, value }: IProps) => {
    const [isActive, setIsActive] = useState(value);
    const handleOnClick = () => {
        setIsActive((prev) => !prev);
        onClick();
    };
    return (
        <StyledSwitchButton isActive={isActive} onClick={handleOnClick}>
            <label htmlFor={label}>{label}</label>
            <button>{label}</button>
        </StyledSwitchButton>
    );
};

export default SwitchButton;
