import styled, { themeGet } from "@doar/shared";

interface IProps {
    isActive: boolean;
}

export const StyledSwitchButton = styled.div<IProps>`
    position: relative;
    display: flex;
    justify-content: ${(props) => (props.isActive ? "flex-end" : "flex-start")};
    align-items: center;
    background-color: ${(props) =>
        props.isActive
            ? themeGet("colors.databoardsActive")
            : themeGet("colors.databoardsBorderGray")};
    border-radius: 49px;
    padding: 2px;
    width: 32px;
    height: 19px;
    transition: all 0.3s ease;
    cursor: pointer;

    & button {
        width: 16px;
        height: 16px;
        background-color: ${themeGet("colors.white")};
        border-radius: ${themeGet("radii.circle")};
        border: none;
        outline: none;
        transition: all 0.2s ease;
        color: transparent;
    }

    & > label {
        position: absolute;
        left: 40px;
        white-space: pre;
    }
`;
