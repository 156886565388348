import { FC, useState } from "react";
import SVGIcon from "../../SVGIcon";
import { Button } from "../button/button";
import ButtonSkeletor from "../ButtonSkeletor";

import { StyledMetricVariantsButton, StyledButton } from "./style";

type TMetric = "column" | "line" | "pie" | "spiderweb" | "gauge";

type TIsLoading =
    | "REFRESH"
    | "FOLDERS"
    | "SURVEYS"
    | "METRIC"
    | "QUESTIONS"
    | "COMPLETE";

interface IProps {
    onClick?: (type: string) => void;
    isLoading: TIsLoading;
    selected: TMetric;
    disableAll: boolean;
    disableGauge: boolean;
}

const chartTypes: TMetric[] = ["column", "line", "pie", "spiderweb", "gauge"];

const SelectMetric: FC<IProps> = ({
    onClick,
    isLoading,
    selected,
    disableAll,
    disableGauge,
}: IProps) => {
    const [metric, setMetric] = useState<TMetric>(selected);
    const handleClick = (selectedMetric: TMetric) => {
        if (onClick) onClick(selectedMetric);

        setMetric(selectedMetric);
    };
    const textDisableGauge = "This question is not compatible with this chart";
    return (
        <StyledMetricVariantsButton>
            {chartTypes.map((chartType) => (
                <ButtonSkeletor
                    key={chartType}
                    height={40}
                    width={40}
                    loading={
                        isLoading === "REFRESH" ||
                        isLoading === "FOLDERS" ||
                        isLoading === "SURVEYS" ||
                        isLoading === "QUESTIONS"
                    }
                >
                    <StyledButton
                        isActive={metric === chartType}
                        key={chartType}
                        title={
                            chartType === "gauge" && disableGauge
                                ? textDisableGauge
                                : chartType
                        }
                    >
                        <Button
                            color="databoards"
                            size="sm"
                            disabled={
                                (chartType === "gauge" && disableGauge) ||
                                disableAll
                            }
                            onClick={() => handleClick(chartType)}
                        >
                            <SVGIcon
                                type={chartType}
                                isActive={metric === chartType}
                                hasFill
                            />
                        </Button>
                    </StyledButton>
                </ButtonSkeletor>
            ))}
        </StyledMetricVariantsButton>
    );
};

export default SelectMetric;
