import { FC, useState, forwardRef } from "react";
import { StyledEditableText } from "./style";

interface IProps {
    value: string;
    truncateText?: boolean;
    onChange?: (e: IChangeEvent) => void;
    onClose?: (value: string) => void;
}

interface IChangeEvent {
    [key: string]: any;
}

/* const EditableText: FC<IProps> = ({
    value,
    onChange,
    onClose = () => {},
}: IProps) => { */
const EditableText = forwardRef<HTMLSpanElement, IProps>(({
    value,
    truncateText = false,
    onChange = () => {},
    onClose = () => {},
}, ref) => {
    const [showInputEle, setShowInputEle] = useState(false);
    const [inputVal, setInputVal] = useState(value);

    const truncate = (text: string, size = 23) => {
        return text.length > size ? text.slice(0, size - 1) + "…" : text;
    };

    return (
        <StyledEditableText>
            {showInputEle ? (
                <input
                    type="text"
                    value={inputVal}
                    onChange={(e) => {
                        setInputVal(e.target.value);
                        onChange(e);
                    }}
                    onBlur={() => {
                        setShowInputEle(false);
                        onClose(inputVal);
                    }}
                    autoFocus
                />
            ) : (
                <span ref={ref} onDoubleClick={() => setShowInputEle(true)}>
                    {truncateText ? truncate(inputVal) : inputVal}
                </span>
            )}
        </StyledEditableText>
    );
});

export default EditableText;
