import styled, { themeGet } from "@doar/shared";

interface IProps {
    isActive: boolean;
}

export const StyledMetricVariantsButton = styled.div`
    display: flex;
    justify-content: space-between;
    //margin-left: 1rem;
    width: 100%;
    & > button > img {
        width: 30px;
        //margin-left: 1rem;
    }

    & > button {
        width: 39px;
        padding: 0.4rem;
    }

    & > button::active {
        background-color: ${themeGet("colors.databoardsDarkBlue")};
    }
`;

export const StyledButton = styled.span<IProps>`
    & button {
        background-color: ${(props) =>
            props.isActive === true
                ? themeGet("colors.databoardsToggleBackground")
                : themeGet("colors.light")};
        border: none;
        height: 40px;
        width: 40px;
    }

    & button:focus {
        background-color: ${themeGet("colors.databoardsToggleBackground")};
    }

    & button:hover {
        background-color: ${themeGet("colors.databoardsToggleBackground")};
    }

    & button:hover .svg-body {
        fill: white;
        stroke-width: 0.2px;
        stroke: white;
    }

    & button:hover .line {
        stroke-width: 2.598px;
    }

    & button img {
        width: 100%;
    }
`;
