/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import { SVGIcon } from "@doar/components";
import { Icon } from "leaflet";
import { StyledToggleButton } from "./style";

type TIcon =
    | "bar"
    | "column"
    | "numbers"
    | "percentage"
    | "stackedColumn"
    | "spiderweb"
    | "line"
    | "curveLine"
    | "solidLine"
    | "areaLine"
    | "areaSpiderweb"
    | "pie"
    | "donut"
    | "pieStyle";

interface IProps {
    left: TIcon;
    right: TIcon;
    onClick: (type: string) => void;
    selected?: "left" | "right";
}

const ToggleButton: React.FC<IProps> = ({
    left,
    right,
    onClick,
    selected = "left",
}: IProps) => {
    const [toggle, setToggle] = useState<"left" | "right">(selected);

    const handleClick = () => {
        setToggle((prev) => {
            const newValue = prev === "left" ? "right" : "left";
            onClick(newValue === "left" ? left : right);
            return newValue;
        });
    };

    let avoidFill = ["curveLine", "solidLine", "areaLine"].includes(left);

    return (
        <StyledToggleButton switch={toggle}>
            <button onClick={handleClick}>
                <SVGIcon
                    type={left}
                    isActive={toggle === "left"}
                    hasFill={!avoidFill}
                />
            </button>
            <button onClick={handleClick}>
                <SVGIcon
                    type={right}
                    isActive={toggle === "right"}
                    hasFill={right !== "solidLine"}
                />
            </button>
        </StyledToggleButton>
    );
};

export default ToggleButton;
