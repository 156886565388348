import styled, { themeGet } from "@doar/shared";

interface IProps {
    show: boolean;
}

export const StyledSeriesName = styled.section<IProps>`
    & button {
        color: ${({ show }) =>
            show
                ? themeGet("colors.databoardsActive")
                : themeGet("colors.text2")};
        transition: all 0.2s ease;
    }

    & button:hover {
        color: ${themeGet("colors.databoardsActive")};
    }

    & button svg {
        color: ${({ show }) =>
            show
                ? themeGet("colors.databoardsActive")
                : themeGet("colors.text2")};
        transition: all 0.3s ease;
        transform: ${({ show }) => (!show ? "" : "rotate(90deg)")};
    }

    & button:hover svg {
        color: ${themeGet("colors.databoardsActive")};
    }
`;

export const StyledMenu = styled.section<IProps>`
    display: ${({ show }) => (show ? "flex" : "none")};
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
`;
