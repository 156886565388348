import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface IProps {
    height: number;
    width: number;
    children: React.ReactNode;
    loading: boolean;
}

const ButtonSkeletor: React.FC<IProps> = ({
    height,
    width,
    children,
    loading,
}: IProps): JSX.Element => {
    if (!loading) {
        return <>{children}</>;
    }
    return (
        <div>
            <SkeletonTheme baseColor="#F3F3F3" highlightColor="#e9e9e9">
                <p>
                    <Skeleton
                        count={1}
                        style={{
                            width,
                            height,
                        }}
                    />
                </p>
            </SkeletonTheme>
        </div>
    );
};

export default ButtonSkeletor;
