import React, { useState } from "react";
import { ChevronRight } from "react-feather";
import { Button } from "../button/button";
import { StyledSeriesName, StyledMenu } from "./style";

interface IProps {
    name: string;
    children: React.ReactNode;
}

const SeriesButton: React.FC<IProps> = ({ name, children }: IProps) => {
    const [show, setShow] = useState(false);
    return (
        <StyledSeriesName show={show}>
            <Button variant="texted" onClick={() => setShow((prev) => !prev)}>
                {name} <ChevronRight />
            </Button>
            <StyledMenu show={show}>{children}</StyledMenu>
        </StyledSeriesName>
    );
};

export default SeriesButton;
