import styled, { themeGet } from "@doar/shared";

interface IProps {
    switch: "left" | "right";
}

export const StyledToggleButton = styled.div<IProps>`
    width: 80px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
    border: 2px solid ${themeGet("colors.databoardsBorderGray")};
    border-radius: 4px;

    // html button style
    & button {
        transition: all 0.2s ease;
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;
        outline: none;
        background-color: ${themeGet("colors.white")};
        border: none;
        border-radius: 2px;
    }

    & button:first-child {
        background-color: ${(props) =>
            props.switch === "left"
                ? themeGet("colors.databoardsToggleBackground")
                : themeGet("colors.white")};
    }

    & button:last-child {
        background-color: ${(props) =>
            props.switch === "right"
                ? themeGet("colors.databoardsToggleBackground")
                : themeGet("colors.white")};
    }
`;
