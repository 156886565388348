import styled, { themeGet } from "@doar/shared";

interface IProps {
    isActive: boolean;
}

export const StyledGaugeButton = styled.div<IProps>`
    & button {
        background-color: ${(props) =>
            props.isActive === true
                ? themeGet("colors.databoardsToggleBackground")
                : themeGet("colors.white")};
        transition: all 0.2s ease;
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;
        outline: none;
        border: 1px solid #e0e0e0;
        border-radius: 2px;
    }
`;

export const StyledGaugeStyles = styled.div`
    width: 163px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0;
    border: 2px solid ${themeGet("colors.databoardsBorderGray")};
    border-radius: 4px;
`;

